import React, { useEffect, useRef } from 'react'
import { ExposeStat } from "../../ubt.v2/exposeStat"
import { RUNTIME_SERVER, PAGE_LIST_URL } from '../../constants/common';

// 抽奖悬浮框所在页面，目前只会在频道首页和列表页
export const FloatLotteryPage = {
    CHANNEL: 'channel',
    LIST: 'list'
}

let OBSERVER_OPTIONS = {
    root: null,
    rootMargin: '0px',
    threshold: [0, 1]
}

export const FloatLottery = ({ source, show, onGetFloatLotteryEl, renderContent, renderEmpty }) => {
    const url = `${RUNTIME_SERVER}/lottery?from=${source}&originpathname=${encodeURIComponent(window.location.href)}`
    const ref = useRef()

    useEffect(() => {
        if (ref && ref.current) {
            let observer = new IntersectionObserver((entries) => {
                const entry = entries[0]
                if (entry) {
                    const { isIntersecting } = entry
                    isIntersecting && onGetFloatLotteryEl({ el: entry.target })
                }
            }, OBSERVER_OPTIONS)

            observer.observe(ref.current)

            return () => observer.disconnect()
        }
    }, [ref])

    return show ? <ExposeStat ubtKey='c_show_float_lottery'>
        {typeof renderContent === 'function' ? renderContent({ ref, url }) : <span />}
    </ExposeStat> : (typeof renderEmpty === 'function' ? renderEmpty({ ref }) : <span />)
}
