import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import moment from 'moment';
import { getIsDepartCountryDomestic, getIsArrivalCountryDomestic } from '../../../../utils/commonUtils';

const QrcodeSubscribe = ({ prevCond, keepQr }) => {
	let flightWay = prevCond.get('flightWay'),
		departureCity = prevCond.getIn(['flightSegments', 0, 'departureCityName']),
		departureCityCode = prevCond.getIn(['flightSegments', 0, 'departureCityCode']),
		arrivalCity = prevCond.getIn(['flightSegments', 0, 'arrivalCityName']),
		arrivalCityCode = prevCond.getIn(['flightSegments', 0, 'arrivalCityCode']),
		departureDate = prevCond.getIn(['flightSegments', 0, 'departureDate']),
		arrivalDate = '',
		rtflag = flightWay == 'D' ? '1' : '0',
		todayStr = moment().format('YYYY-MM-DD'),
		bpushdate = departureDate, //最早出发时间
		epushdate = '', //最晚出发时间
		sflag = 1,
		departCountryDomestic = getIsDepartCountryDomestic(prevCond.getIn(['flightSegments', 0])),
		arrivalCountryDomestic = getIsArrivalCountryDomestic(prevCond.getIn(['flightSegments', 0]));

	// 单程情况下
	// a）如果出发日期是today，则最早出发日期为toady，最晚出发日期为today+3
	// b）如果出发日期不是today，则最早出发日期是today-1，最晚出发日期为toady+2
	if (flightWay == 'S') {
		if (moment(departureDate).isAfter(todayStr, 'day')) {
			bpushdate = moment(departureDate).subtract(1, 'days').format('YYYY-MM-DD');
			epushdate = moment(departureDate).add(2, 'days').format('YYYY-MM-DD');
		} else {
			epushdate = moment(departureDate).add(3, 'days').format('YYYY-MM-DD');
		}
	}

	// sflag === 1表示出发地为国际
	// sflag === 2 表示目的地为国际
	// sflag === 3 表示出发和目的都为国际
	// sflag === 0 表示出发和目的都为国内
	if (!departCountryDomestic && arrivalCountryDomestic) {
		sflag = 1;
	} else if (departCountryDomestic && !arrivalCountryDomestic) {
		sflag = 2;
	} else if (!departCountryDomestic && !arrivalCountryDomestic) {
		sflag = 3;
	} else {
		sflag = 0;
	}

	if (flightWay == 'D' && prevCond.getIn(['flightSegments']).size == 2) {
		arrivalDate = prevCond.getIn(['flightSegments', 1, 'departureDate']);
	}
	let business_param = {
		bpushdate: bpushdate,
		epushdate: epushdate,
		rbpushdate: arrivalDate,
		depname: departureCity,
		arrname: arrivalCity,
		depcode: departureCityCode,
		arrcode: arrivalCityCode,
		sflag: sflag,
		rtflag: rtflag,
	};

	let url = `ctrip://wireless/flight_low_price_subscribe?c1=lowPriceEdit&c2=${JSON.stringify(
		business_param
	)}&c3={"tag":{"originSource":"onlineCodeCard"}}`;

	const getPrefRight = () => {
		const right = Math.floor((document.body.clientWidth - 1180) / 2);
		return Math.max(0, right);
	};

	const [prefRight, setPrefRight] = useState(getPrefRight());

	const [lastRisizeWindow, setLastRisizeWindow] = useState(new Date(2019, 0, 1));

	useEffect(() => {
		const handleResizeWindow = () => {
			if (new Date() - lastRisizeWindow >= 50) {
				const right = getPrefRight();
				if (prefRight !== right) {
					setPrefRight(right);
				}

				setLastRisizeWindow(new Date());
			}
		};

		window.addEventListener('resize', handleResizeWindow, true);

		return () => {
			window.removeEventListener('resize', handleResizeWindow, true);
		};
	});

	return keepQr ? (
		<div className="qrcode">
			<QRCodeCanvas size={130} value={url} />
		</div>
	) : null;
};

export default QrcodeSubscribe;
