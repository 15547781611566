import React from 'react';
import { connect } from 'react-redux';
import Filterbar from '../../../../components/list/sidebar/filterbar/filterbar';
import {
	filtersWrapperSelector,
	visibleFlightsCountSelector,
	visibleDirectFlightsCountSelector,
	visibleFlightsAirCraftSizeListSelector,
	getVisibleFlightsSegmentTypeGroupSelector,
	filterVisibleFlightsSelector,
} from './filterbarSelector';
import { genClickClearAll } from '../../../../actions/list/filter';
import {
	searchFlightsIsFinishedSelector,
	containsTaxSelector,
	anyActiveKeysOrNotContainsTaxSelector,
	getSortTypesSelector,
	isBuildUpSelector,
	prevCondSelector,
	getFixSortBarSelector,
	getFixLowPriceCalendarSelector,
	currentSegmentSeqSelector,
	getSearchInfoToUbtSelector,
	getPassengerCountArrayFromPrevCondSelector,
	getAllRecommendFlightsListSelector,
	getActiveRoundTabIndexSelector,
	giftIdListSelector,
	getTheMinPriceOfAllFlightsSelector,
} from '../../result/flight/baseSelector';
import { genSwitchIncludeTax } from '../../../../actions/list';
import { calcSplitRoundSelector } from '../../../../sources/list/calcSplitRoundStateSelector';
import { flightWaySelector } from '../../selector/search';
import { getResultInfoUbtSelector } from '../../result/recommend/baseRecommendSelector';
import { getIfShowRoundTabModeSelector } from '../../result/recommend/recommendSelector';
import { getIfAnyAirlineMatrixDataSelector } from '../../top/airlineMatrix/airlineMatrixSelector';

class FilterBarContainer extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <Filterbar hasFlights={this.props.flights.size > 0} {...this.props} />;
	}
}

const mapStateToProps = (state, props) => {
	const { splitRound } = props,
		// flights = calcSplitRoundSelector(splitRound, flightListSelector, state, true),
		flights = calcSplitRoundSelector(splitRound, filterVisibleFlightsSelector, state, true),
		filters = calcSplitRoundSelector(splitRound, filtersWrapperSelector, state, true),
		visibleFlightsCount = calcSplitRoundSelector(splitRound, visibleFlightsCountSelector, state, true),
		visibleDirectFlightsCount = calcSplitRoundSelector(splitRound, visibleDirectFlightsCountSelector, state, true),
		visibleFlightsAirCraftSizeList = calcSplitRoundSelector(
			splitRound,
			visibleFlightsAirCraftSizeListSelector,
			state,
			true
		),
		sortTypes = calcSplitRoundSelector(splitRound, getSortTypesSelector, state, true),
		anyActiveKeysOrNotContainsTax = calcSplitRoundSelector(
			splitRound,
			anyActiveKeysOrNotContainsTaxSelector,
			state,
			true
		),
		currentSegmentSeq = calcSplitRoundSelector(splitRound, currentSegmentSeqSelector, state, true),
		theMinPriceOfAllFlights = calcSplitRoundSelector(splitRound, getTheMinPriceOfAllFlightsSelector, state, true);

	return {
		searchFlightsIsFinished: searchFlightsIsFinishedSelector(state),
		flights,
		filters,
		visibleFlightsCount,
		visibleDirectFlightsCount,
		containsTax: containsTaxSelector(state),
		anyActiveKeysOrNotContainsTax,
		sortTypes,
		isBuildUp: isBuildUpSelector(state),
		prevCond: prevCondSelector(state),
		filterV2: true,
		fixSortBar: getFixSortBarSelector(state),
		fixLowPriceCalendar: getFixLowPriceCalendarSelector(state),
		visibleFlightsAirCraftSizeList,
		currentSegmentSeq,
		splitRoundFlightsSwitch: false,
		flightWay: flightWaySelector(state),
		ubtSearchInfo: getSearchInfoToUbtSelector(state),
		ubtResultInfo: getResultInfoUbtSelector(state),
		passengerCountArrayFromPrevCond: getPassengerCountArrayFromPrevCondSelector(state),
		allRecommendFlightsList: getAllRecommendFlightsListSelector(state),
		showTabRoundMode: getIfShowRoundTabModeSelector(state),
		activeRoundTabIndex: getActiveRoundTabIndexSelector(state),
		anyMatrixData: getIfAnyAirlineMatrixDataSelector(state),
		giftIdList: giftIdListSelector(state),
		visiblePriceSortTypeGroup: getVisibleFlightsSegmentTypeGroupSelector(state),
		theMinPriceOfAllFlights,
	};
};

const mapDispatchToProps = (dispatch) => ({
	handleClickRemoveAll: (resetContainsTax, splitRound) => {
		dispatch(genClickClearAll(resetContainsTax, splitRound));
	},
	onToggleContainsTax: () => {
		dispatch(genSwitchIncludeTax());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterBarContainer);
