import React from 'react';
import classnames from 'classnames';
import { sendBlockTrace } from '@/src/utils/sendBlockTrace';
import { BlockTypeValue, InterruptTypeValue, MainTypeValue, SubTypeValue } from '@/src/utils/types';
import { FloatSideRecommend } from './recommends';
import { FloatSideSubscribe } from './subscribe';
import { GuideLineContainer } from '../../../containers/list/result/guideLine/guideLineContainer';
import { FloatAirlineMatrix } from './floatAirlineMatrix';
import AdBar from '../result/recommend/AdBarAsync';
import { FloatLottery, FloatLotteryPage } from '../../Base/floatLottery';
import { lightUpload } from '../../../sources/common/lightUbt';
import Army from './army';
import Gift from './giftBox';
import DomesticPriceSortTypeGroup from './DomesticPriceSortTypeGroup';
import { getIfScopeInternational } from '../../../sources/list/scope';

export const FloatSideBar = (props) => {
	const {
		prevCond,
		passengerCountArrayFromPrevCond,
		filterV2,
		allRecommendFlightsList,
		resultInfo,
		searchInfo,
		fixSortBar,
		showTabRoundMode,
		activeRoundTabIndex,
		anyMatrixData,
		lotterFloatShow,
		onGetFloatLotteryEl,
		setArmyPopupShow,
		setArmy,
		army,
		currentSegmentSeq,
		giftIdList,
		visiblePriceSortTypeGroup,
		priceSortTypeGroupTitlesRef,
		globalSwitch,
		theMinPriceOfAllFlights,
	} = props;
	const adBarSwitch = globalSwitch && globalSwitch.get('adBarSwitch');

	const flightWay = prevCond.get('flightWay');

	const renderFloatLottery = ({ ref, url }) => {
		const uploadClickUbt = () => lightUpload(`c_click_float_lottery`, 'list');

		return (
			<li className="no-hover" ref={ref} onClick={uploadClickUbt}>
				<div className="title">
					<a href={url} target="_blank" rel="noopener noreferrer">
						<img src="//pic.c-ctrip.com/flight_intl/lottery/lottery-floating.png" alt="Lottery" />
					</a>
				</div>
			</li>
		);
	};

	const renderEmptyFloatLottery = ({ ref }) => <li className="no-hover hidden" ref={ref} />;

	if (lotterFloatShow) {
		sendBlockTrace({
			blockType: BlockTypeValue.Pop,
			interruptType: InterruptTypeValue.Block,
			mainType:  MainTypeValue.Search,
			subType: SubTypeValue.Lottery,
		});
	}
	
	return (
		<div className={classnames({ 'aside-bar': true, 'fix-sortbar': fixSortBar })}>
			{/* hover类 */}
			<ul className="tool-list">
				{getIfScopeInternational() && showTabRoundMode && anyMatrixData && (
					<FloatAirlineMatrix activeRoundTabIndex={activeRoundTabIndex} />
				)}
				<FloatSideRecommend
					searchInfo={searchInfo}
					resultInfo={resultInfo}
					prevCond={prevCond}
					passengerCountArrayFromPrevCond={passengerCountArrayFromPrevCond}
					allRecommendFlightsList={allRecommendFlightsList}
					theMinPriceOfAllFlights={theMinPriceOfAllFlights}
				/>
				<GuideLineContainer />
				<Gift giftIdList={giftIdList} prevCond={prevCond} currentSegmentSeq={currentSegmentSeq} />
				{flightWay != 'M' && <FloatSideSubscribe prevCond={prevCond} filterV2={filterV2} />}
			</ul>
			{/* 点击类 */}
			<ul className="tool-list">
				<DomesticPriceSortTypeGroup
					visiblePriceSortTypeGroup={visiblePriceSortTypeGroup}
					priceSortTypeGroupTitlesRef={priceSortTypeGroupTitlesRef}
					fixSortBar={fixSortBar}
				/>
				<Army setArmyPopupShow={setArmyPopupShow} setArmy={setArmy} army={army} />
			</ul>
			<ul className="tool-list">
				{adBarSwitch ? <AdBar adConfigKey="ASIDE" classNames="aside" prevCond={prevCond} /> : null}
				<FloatLottery
					source={FloatLotteryPage.LIST}
					show={lotterFloatShow}
					onGetFloatLotteryEl={onGetFloatLotteryEl}
					renderContent={renderFloatLottery}
					renderEmpty={renderEmptyFloatLottery}
				/>
			</ul>
		</div>
	);
};
