import React from 'react';
import { scrollTo } from 'scroll-js';

const fixFilterBarHeight = 64,
	fixCalendar = 68;

export const scrollToElement = (current) => {
	const top = current.getBoundingClientRect().top;
	const offset = top + window.scrollY - (top > 0 ? 0 : fixCalendar) - fixFilterBarHeight;
	scrollTo(window, { top: offset, behavior: 'smooth', duration: 50 }).then(() => {
		const top = current.getBoundingClientRect().top;
		const offset = top + window.scrollY - (top > 0 ? 0 : fixCalendar) - fixFilterBarHeight;
		if (top > 300) {
			scrollToElement(current);
		} else {
			scrollTo(window, { top: offset, behavior: 'smooth', duration: 50 });
		}
	});
};
const DomesticPriceSortTypeGroup = ({ visiblePriceSortTypeGroup, priceSortTypeGroupTitlesRef }) => {
	const onItemClick = (key) => {
		const { current } = priceSortTypeGroupTitlesRef[key] || {};
		if (!current) return;
		scrollToElement(current);
	};
	return (
		<React.Fragment>
			{Object.keys(visiblePriceSortTypeGroup).map((typeGroupKey, index) => {
				const { count, title, key, classname, showInSideBarTitle } = visiblePriceSortTypeGroup[typeGroupKey];
				if (count > 0) {
					return (
						<li className={`no-hover ${classname}`} key={index} onClick={() => onItemClick(key)}>
							<div
								className="title"
								dangerouslySetInnerHTML={{ __html: showInSideBarTitle || title }}
							></div>
						</li>
					);
				} else {
					return null;
				}
			})}
		</React.Fragment>
	);
};

export default DomesticPriceSortTypeGroup;
