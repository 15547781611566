import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import { sendVetTrace } from '@/src/sources/common/lightUbt';
import ErrorBoundary from '@/src/components/Base/ErrorBoundary';

import { LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES, ListUbtGenerator, UbtBoundary } from '../../../../ubt.v2/list';
import { FilterItemV2, getIfClickFilterTitleOrOptions } from './filterItemV2'
import { scrollToFirstFlight } from '../../../../sources/list/scrollToFirstFlight';
import { FloatSideBar } from '../../floatSideBar';

const SortBar = ({ sortTypes, isBuildUp, prevCond, globalSwitch, currentSegmentSeq }) => {
    return (
        <ul className='sortbar-v2'>
            {sortTypes.map(entry => entry.render(isBuildUp, prevCond, globalSwitch, currentSegmentSeq))}
        </ul>)
}

const Filterbar = ({ hasFlights, searchFlightsIsFinished, filters,
    visibleDirectFlightsCount, handleClickRemoveAll, globalSwitch, containsTax,
    onToggleContainsTax, anyActiveKeysOrNotContainsTax, sortTypes, visibleFlightsAirCraftSizeList,
    isBuildUp, prevCond, fixSortBar, fixLowPriceCalendar, currentSegmentSeq, flightWay,
    ubtSearchInfo, ubtResultInfo, passengerCountArrayFromPrevCond, allRecommendFlightsList,
    showTabRoundMode, activeRoundTabIndex, anyMatrixData, lotterFloatShow,
    onGetFloatLotteryEl, splitRound, setArmyPopupShow, setArmy, army, giftIdList,
    onSetHideSharedFlights, visiblePriceSortTypeGroup, priceSortTypeGroupTitlesRef, theMinPriceOfAllFlights }) => {
    const topBarRef = React.createRef()
    let selectesSortTypeStr = '',
        selectedFiltersStr = ''

    sortTypes.forEach((st) => {
        selectesSortTypeStr += st.key + ':' + st.activeIndex + '|'
    })
    filters.valueSeq().forEach(filter => {
        selectedFiltersStr += filter.get('activeKeys') + '|'
    })
    useEffect(() => {
        scrollToFirstFlight(fixSortBar, fixLowPriceCalendar)
    }, [selectedFiltersStr, selectesSortTypeStr])

    const [popupItemKey, setPopupItemKey] = useState(''),
        toggleSetPopupItemKey = (key, clickOptions) => {
            setPopupItemKey(key && !clickOptions && (popupItemKey === key) ? '' : key)
        }

    useEffect(() => {
        if (popupItemKey) {
            const handleClickBody = (ev) => {
                if (!getIfClickFilterTitleOrOptions(ev, topBarRef)) {
                    // 如果点击的不是筛选项，就清掉菜单的弹出状态
                    toggleSetPopupItemKey('', false)
                }
            }

            document.body.addEventListener('click', handleClickBody, true)

            return () => {
                document.body.removeEventListener('click', handleClickBody, true)
            }
        }
    })

    // 搜索框的航程类型和搜索结果航程类型显示一致才展示推荐
    const pageFlightWay = prevCond.get('flightWay')
    const resultFlightWaySameWithSearchFlightWay = (pageFlightWay == 'S' && flightWay == 'OW') || (pageFlightWay == 'D' && flightWay == 'RT') || (pageFlightWay == 'M' && flightWay == 'MT')

    return (
        <div className={classnames('topbar-v2-root', { 'no-flights': !hasFlights })} >
            <div ref={topBarRef} className={classnames({ 'topbar-v2': true, fixed: fixSortBar, lower: fixSortBar && fixLowPriceCalendar, finish: searchFlightsIsFinished })}>
                {hasFlights && <React.Fragment>
                    <ul className='filterbar-v2'>
                        {filters.sort((t1, t2) => t1.get('seq') - t2.get('seq')).map((t) => {
                            const key = t.get('type').key
                            return (
                                <ErrorBoundary key={key}>
                                    <FilterItemV2 containsTax={containsTax} popup={key === popupItemKey} topBarRef={topBarRef}
                                        toggleSetPopupItemKey={toggleSetPopupItemKey} filter={t}
                                        onToggleContainsTax={onToggleContainsTax} visibleFlightsAirCraftSizeList={visibleFlightsAirCraftSizeList}
                                        visibleDirectFlightsCount={visibleDirectFlightsCount} currentSegmentSeq={currentSegmentSeq}
                                        splitRound={splitRound} onSetHideSharedFlights={onSetHideSharedFlights} />
                                </ErrorBoundary>)
                        })}
                        {anyActiveKeysOrNotContainsTax ?
                            <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.FILTER_ITEM_CLICK, LIST_UBT_KEY_TYPES.FILTER_CLEAR_ALL)}>
                                <li onClick={() => {
                                    sendVetTrace({
                                        key: 'online_flight_filter_item_click_test'
                                    });
                                    handleClickRemoveAll(true, splitRound)
                                }}><span className='reset-filter'>清空</span></li>
                            </UbtBoundary> : null}
                    </ul>
                    {React.createElement(SortBar, { sortTypes, isBuildUp, prevCond, globalSwitch, hasFlights, currentSegmentSeq })}
                </React.Fragment>}

                {resultFlightWaySameWithSearchFlightWay && <FloatSideBar fixLowPriceCalendar={fixLowPriceCalendar} fixSortBar={fixSortBar}
                    searchInfo={ubtSearchInfo} resultInfo={ubtResultInfo} prevCond={prevCond} passengerCountArrayFromPrevCond={passengerCountArrayFromPrevCond} filterV2={true}
                    allRecommendFlightsList={allRecommendFlightsList} lotterFloatShow={lotterFloatShow}
                    showTabRoundMode={showTabRoundMode} activeRoundTabIndex={activeRoundTabIndex} anyMatrixData={anyMatrixData}
                    onGetFloatLotteryEl={onGetFloatLotteryEl} setArmyPopupShow={setArmyPopupShow} setArmy={setArmy}
                    army={army} currentSegmentSeq={currentSegmentSeq} giftIdList={giftIdList}
                    visiblePriceSortTypeGroup={visiblePriceSortTypeGroup}
                    priceSortTypeGroupTitlesRef={priceSortTypeGroupTitlesRef}
                    topBarRef={topBarRef} globalSwitch={globalSwitch}
                    theMinPriceOfAllFlights={theMinPriceOfAllFlights}
                />}
            </div>
        </div >)
};

export default Filterbar
