import React from 'react'
import { scopeFunction } from '../../../sources/list/scopeFunction'

const Army = ({ setArmyPopupShow, army, setArmy }) => {
    return !army ? <li className='army-book no-hover'>
        <div className="title" onClick={() => {
            setArmyPopupShow(true);
        }}>
            <p>伤残军警</p>
            <p>通道</p>
        </div>
    </li > : <li className='normal-book no-hover'>
        <div className="title" onClick={() => { setArmy(false) }}>
            <p>普通乘客</p>
            <p>预订</p>
        </div>
    </li>

}
export default scopeFunction({ i: () => null, d: Army })