import React from 'react'
import classnames from 'classnames'
import Immutable from 'immutable'
import { sendVetTrace } from '@/src/sources/common/lightUbt';
import { filterOptionsGenerator } from './filterGenerator'

const FILTER_ROOT_CLASS_NAME = 'filter-item-v2-root-flag'
const FILTER_OPTIONS_CLASS_NAME = 'filter-options'

const getIfAnyParentHasClass = (el, className) => {
    let parentEl = el
    while (parentEl) {
        if ((parentEl.getAttribute('class') || '').indexOf(className) >= 0) {
            return true
        } else {
            parentEl = parentEl.parentElement
        }
    }

    return false
}

const getIfChildOf = (el, rootRef) => {
    return rootRef.current?.contains(el)
}

export const getIfClickFilterItemTitle = (ev, topBarRef) => {
    const target = ev.target
    const isChildOfTopBar = getIfChildOf(target, topBarRef)
    return isChildOfTopBar && getIfAnyParentHasClass(target, FILTER_ROOT_CLASS_NAME, topBarRef)
}

export const getIfClickFilterOptions = (ev, topBarRef) => {
    const target = ev.target
    const isChildOfTopBar = getIfChildOf(target, topBarRef)
    return isChildOfTopBar && getIfAnyParentHasClass(target, FILTER_OPTIONS_CLASS_NAME, topBarRef)
}

export const wrapFuncWithParams = (func, ...params) => {
    return () => func(...params)
}

export const boolOrFuncs = (...funcs) => {
    return funcs.some(func => func())
}

export const getIfClickFilterTitleOrOptions = (ev, topBarRef) => boolOrFuncs(wrapFuncWithParams(getIfClickFilterItemTitle, ev, topBarRef), wrapFuncWithParams(getIfClickFilterOptions, ev, topBarRef))

export const FilterItemV2 = ({ filter, containsTax, popup, visibleFlightsAirCraftSizeList,
    toggleSetPopupItemKey, onToggleContainsTax, onSetHideSharedFlights, splitRound, topBarRef,
    currentSegmentSeq }) => {

    let type = filter.get('type'),
        groups = filter.get('groups'),
        visible = filter.get('visible'),
        allItems = groups
            .map(t => t.get('items'))
            .reduce((prev, cur) => prev.valueSeq().concat(cur), Immutable.List([])),
        allItemsDisable = allItems.every(t => !t.get('enable')),
        renderFunc = filter.get('render'),
        activeKeys = filter.get('activeKeys'),
        isOtherFilter = filter.get('type').key === 'OTHER',	//V2 更多 筛选项
        anyActiveKeyInFilter = !!filter.get('groups').keySeq().find(groupKey =>
            activeKeys.some(activeKey => filter.get('groups').getIn([groupKey, 'items']).find(item => {
                const matched = item.get('key') === activeKey
                const isZeroItem = item.get('key').key === 'ZERO'

                return !isZeroItem && matched
            }))
        ),
        selectAnyOption = isOtherFilter ? (!containsTax || anyActiveKeyInFilter) : anyActiveKeyInFilter,
        onClickItem = ev => {
            sendVetTrace({
                key: 'online_flight_filter_item_click_test'
            });
            if (getIfClickFilterTitleOrOptions(ev, topBarRef)) {
                toggleSetPopupItemKey(type.key, getIfClickFilterOptions(ev, topBarRef))
            }
        },
        onCloseMenu = ev => {
            toggleSetPopupItemKey('', false)
            ev.stopPropagation()
        },
        title = type.value.title

    if (visible) {
        return renderFunc ? renderFunc({ filter, allItems, title, containsTax, popup, onClickItem, selectAnyOption, visibleFlightsAirCraftSizeList }, { onToggleContainsTax, onSetHideSharedFlights, onCloseMenu, splitRound, currentSegmentSeq }) : (
            <li id={`filter_item_${type.key.toLowerCase()}`} className={classnames({ [FILTER_ROOT_CLASS_NAME]: true, disabled: allItemsDisable, popup })} onClick={onClickItem} >
                <div className={`filter-key${selectAnyOption ? ' has-selected' : ''}`}>{title}
                    {popup ? <i className="iconf-arrow-up">&#xe604;</i> : <i className="iconf-arrow-down">&#xe60c;</i>}

                </div>
                {popup && filterOptionsGenerator(filter, containsTax, { onCloseMenu, onToggleContainsTax, onSetHideSharedFlights, splitRound, currentSegmentSeq })}
            </li>)
    } else {
        return null
    }
}
