import React from 'react';
import { connect } from 'react-redux';

import ErrorBoundary from '@/src/components/Base/ErrorBoundary';

import { getGuideLineSelector, getShowGuileLineSelector } from './guideLineSelector';
import { UbtBoundary } from '../../../../ubt.v2/boundary';
import { ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list';
import { getFirstPrevCondSegmentSelector } from '../flight/baseSelector';
import PopDialog from '../../../../components/list/common/PopDialog';
import { GUIDE_LINE_TAG_MAP } from '../../../../sources/list/guideLineConst';
import { IntersectionNotifier } from '../../../../components/Base/intersetionNotifier';

class _GuideLineContainer extends React.Component {
	constructor(props) {
		super(props);

		this.onChangeActiveTag = this.onChangeActiveTag.bind(this);
		this.onToggleExpand = this.onToggleExpand.bind(this);
		this.onPopTravelSpotImage = this.onPopTravelSpotImage.bind(this);
		this.onHideTravelSpotImage = this.onHideTravelSpotImage.bind(this);

		this.openPopUbtHandler = ListUbtGenerator(
			LIST_UBT_GROUP_TYPES.GUIDE_LINE,
			LIST_UBT_KEY_TYPES.GUIDE_LINE_CLICK,
			'click_open_entrance'
		);
		this.closePopUbtHandler = ListUbtGenerator(
			LIST_UBT_GROUP_TYPES.GUIDE_LINE,
			LIST_UBT_KEY_TYPES.GUIDE_LINE_CLICK,
			'click_close_entrance'
		);
		this.tabUbtHandlerList = [
			{
				type: GUIDE_LINE_TAG_MAP.PRICE_CHANGE_HISTORY,
				handler: ListUbtGenerator(
					LIST_UBT_GROUP_TYPES.GUIDE_LINE,
					LIST_UBT_KEY_TYPES.GUIDE_LINE_CLICK,
					'click_price_change'
				),
			},
			{
				type: GUIDE_LINE_TAG_MAP.PRICE_PREDICTION,
				handler: ListUbtGenerator(
					LIST_UBT_GROUP_TYPES.GUIDE_LINE,
					LIST_UBT_KEY_TYPES.GUIDE_LINE_CLICK,
					'click_price_prediction'
				),
			},
			{
				type: GUIDE_LINE_TAG_MAP.TRAVEL_TIPS,
				handler: ListUbtGenerator(
					LIST_UBT_GROUP_TYPES.GUIDE_LINE,
					LIST_UBT_KEY_TYPES.GUIDE_LINE_CLICK,
					'click_travel_tips'
				),
			},
		];

		this.state = {
			expand: false,
			activeTag: '',
			popTravelSpotImage: false,
			popTravelSpotItem: null,
			popTravelSpotImageIndex: 0,
			asyncGuideline: () => (
				<IntersectionNotifier
					onScrollIntoView={() => {
						import(
							/* webpackChunkName: "guideline" */ '../../../../components/list/floatSideBar/guide'
						).then((component) => this.setState({ asyncGuideline: component.default }));
					}}
				/>
			),
		};
	}

	onChangeActiveTag(activeTag) {
		if (activeTag !== this.state.activeTag) {
			this.setState({
				activeTag,
				expand: true,
			});
		} else {
			this.setState({
				expand: true,
			});
		}

		const theUbtHandler = this.tabUbtHandlerList.find((item) => item.type === activeTag);
		if (theUbtHandler && theUbtHandler.handler) {
			LIST_UBT_KEY_TYPES.GUIDE_LINE_CLICK.value.process(theUbtHandler.handler);
		}
	}

	onToggleExpand(expand) {
		if (expand !== this.state.expand) {
			this.setState({ expand });
		}

		if (expand) {
			LIST_UBT_KEY_TYPES.GUIDE_LINE_CLICK.value.process(this.openPopUbtHandler);
		} else {
			LIST_UBT_KEY_TYPES.GUIDE_LINE_CLICK.value.process(this.closePopUbtHandler);
		}
	}

	onHideTravelSpotImage() {
		this.setState({
			popTravelSpotImage: false,
		});
	}

	onPopTravelSpotImage(index, item) {
		Array.from(document.querySelectorAll('.ToolTipPortal')).forEach((item) => {
			if (item.children && item.children[0]) {
				item.children[0].style.visibility = 'hidden';
			}
		});

		this.setState({
			popTravelSpotImage: true,
			popTravelSpotItem: item,
			popTravelSpotImageIndex: index,
		});
	}

	render() {
		const showGuileLine = this.props.showGuileLine;
		if (showGuileLine) {
			let guideLineWrapper = this.props.guideLineWrapper,
				firstPrevCondSegment = this.props.firstPrevCondSegment,
				pricePrediction = guideLineWrapper.get(GUIDE_LINE_TAG_MAP.PRICE_PREDICTION),
				priceChangeHistory = guideLineWrapper.get(GUIDE_LINE_TAG_MAP.PRICE_CHANGE_HISTORY),
				travelTips = guideLineWrapper.get(GUIDE_LINE_TAG_MAP.TRAVEL_TIPS),
				travelTipsSpotImageCount = 0;

			if (travelTips) {
				travelTipsSpotImageCount = travelTips.get('hotSpotsList').size;
			}

			const currentPopTravelSpotImageIndex = this.state.popTravelSpotImageIndex,
				travelSpotImagesHasPrev = currentPopTravelSpotImageIndex > 0,
				travelSpotImagesHasNext = currentPopTravelSpotImageIndex < travelTipsSpotImageCount - 1,
				onPrevClick = travelSpotImagesHasPrev
					? () =>
							this.onPopTravelSpotImage(
								currentPopTravelSpotImageIndex - 1,
								travelTips.getIn(['hotSpotsList', currentPopTravelSpotImageIndex - 1])
							)
					: null,
				onNextClick = travelSpotImagesHasNext
					? () =>
							this.onPopTravelSpotImage(
								currentPopTravelSpotImageIndex + 1,
								travelTips.getIn(['hotSpotsList', currentPopTravelSpotImageIndex + 1])
							)
					: null,
				popImage = this.state.popTravelSpotItem
					? this.state.popTravelSpotItem.get('popImage') || this.state.popTravelSpotItem.get('rawImage')
					: '',
				description = this.state.popTravelSpotItem ? this.state.popTravelSpotItem.get('description', '') : '',
				name = this.state.popTravelSpotItem ? this.state.popTravelSpotItem.get('name', '') : '',
				nameContent = name ? `<h3>${name}</h3>` : '',
				guideLineParams = {
					firstPrevCondSegment,
					pricePrediction,
					priceChangeHistory,
					travelTips,
					...this.state,
					onChangeActiveTag: this.onChangeActiveTag,
					onToggleExpand: this.onToggleExpand,
					onPopTravelSpotImage: this.onPopTravelSpotImage,
					filterV2: this.props.filterV2,
				};

			return pricePrediction || priceChangeHistory || travelTips ? (
				<React.Fragment>
					{this.state.popTravelSpotImage && (
						<PopDialog
							style={{ overflow: 'hidden' }}
							onClickBackground={this.onHideTravelSpotImage}
							contents={
								<React.Fragment>
									<div className="popups popup-guide-line-spot">
										<div className="body" style={{ width: '900px', height: '600px' }}>
											<i className="ico-close-wb" onClick={this.onHideTravelSpotImage}></i>
											<i
												style={{
													position: 'absolute',
													top: '10px',
													left: '10px',
													color: 'white',
												}}
											>
												{`${this.state.popTravelSpotImageIndex + 1}`}/{travelTipsSpotImageCount}
											</i>
											<img
												src={popImage}
												style={{ width: '900px', verticalAlign: 'bottom' }}
												alt=""
											/>
											<div
												className="description"
												dangerouslySetInnerHTML={{
													__html: `${nameContent}${description}`,
												}}
											></div>
											<div
												className={travelSpotImagesHasPrev ? 'prev' : 'prev prev-disabled'}
												onClick={onPrevClick}
												style={{
													cursor: travelSpotImagesHasPrev ? 'pointer' : 'auto',
												}}
											></div>
											<div
												className={travelSpotImagesHasNext ? 'next' : 'next next-disabled'}
												onClick={onNextClick}
												style={{
													cursor: travelSpotImagesHasNext ? 'pointer' : 'auto',
												}}
											></div>
										</div>
									</div>
								</React.Fragment>
							}
						/>
					)}
					<UbtBoundary
						tracker={ListUbtGenerator(
							LIST_UBT_GROUP_TYPES.GUIDE_LINE,
							LIST_UBT_KEY_TYPES.GUIDE_LINE_LOAD,
							'entrance_show',
							'1'
						)}
					>
						<ErrorBoundary>
							<this.state.asyncGuideline {...guideLineParams} />
						</ErrorBoundary>
					</UbtBoundary>
				</React.Fragment>
			) : null;
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => {
	return {
		guideLineWrapper: getGuideLineSelector(state),
		showGuileLine: getShowGuileLineSelector(state),
		firstPrevCondSegment: getFirstPrevCondSegmentSelector(state),
		filterV2: true,
	};
};

export const GuideLineContainer = connect(mapStateToProps, {})(_GuideLineContainer);
