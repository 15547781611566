import React from 'react'
import QrcodeSubscribe from '../result/recommend/qrcodeSubscribe';

export const FloatSideSubscribe = (props) => {
    const prevCond = props.prevCond

    return (
        <li className='low-price-remind'>
            <div className='title'>
                低价提醒
            </div>
            <div className='content low-price-remind-content'>
                <h3>
                    Hi，还没找到想要的机票吗？
                        </h3>
                <div className='sub-title'>
                    实时监控价格，降价自动推送
                        </div>
                <QrcodeSubscribe prevCond={prevCond} keepQr={true} />
                <div className='scan-des'>
                    扫描二维码，添加低价提醒
                        </div>
                <div className='des'>
                    仅支持携程APP扫码
                        </div>
            </div>
        </li>
    )
}