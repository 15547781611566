import React from 'react';

import ErrorBoundary from '@/src/components/Base/ErrorBoundary';

import AirlineMatrixContainer from '../../../containers/list/top/airlineMatrix/asyncDelay';

export const FloatAirlineMatrix = ({ activeRoundTabIndex }) => {
	return (
		<li className="float-airline-matrix">
			<div className="title">航司比价</div>
			<div className="content float-airline-matrix-content">
				<ErrorBoundary>
					<AirlineMatrixContainer alwaysShow={true} activeRoundTabIndex={activeRoundTabIndex} />
				</ErrorBoundary>
			</div>
		</li>
	);
};
