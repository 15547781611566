export const GUIDE_LINE_TAG_MAP = {
    PRICE_CHANGE_HISTORY: 'priceChangeHistory',
    PRICE_PREDICTION: 'pricePrediction',
    TRAVEL_TIPS: 'travelTips'
}

export const PRICE_TREND_MAP = {
    '-1': {
        floatSideTitle: '可能降价',
        plainTabHeader: '未来价格可能会',
        strongTabHeader: '下降',
        tabContentHeader: '下降',
        tabContent: '机票价格实时波动。大数据价格预测显示，未来可能会有更低的价格，建议加入低价订阅，持续观望。但是就像天气预报一样，预测数据存在一定误差，仅供参考。',
        spanClassName: 'futrue-decline',
        iconClassName: 'ico-decline',
        tinyClassName: 'decline',
        ubtValue: 'down'
    },
    '0': {
        floatSideTitle: '价格预测',
        plainTabHeader: '未来价格',
        strongTabHeader: '下降概率低',
        tabContentHeader: '下降概率低',
        tabContent: '机票价格实时波动。大数据价格预测显示，未来价格下调概率较小，建议现在就订。但是就像天气预报一样，预测数据存在一定误差，仅供参考。',
        spanClassName: 'futrue-keep',
        iconClassName: 'ico-rise',
        tinyClassName: '',
        ubtValue: 'stay'
    },
    '1': {
        floatSideTitle: '可能涨价',
        plainTabHeader: '未来价格可能会',
        strongTabHeader: '上涨',
        tabContentHeader: '波动上涨',
        tabContent: '机票价格实时波动。大数据价格预测显示，未来价格上涨概率较大，建议尽快预订。但是就像天气预报一样，预测数据存在一定误差，仅供参考。',
        spanClassName: 'futrue-rise',
        iconClassName: 'ico-rise',
        tinyClassName: 'rise',
        ubtValue: 'up'
    },
    '2': {
        floatSideTitle: '可能涨价',
        plainTabHeader: '未来价格可能会',
        strongTabHeader: '上涨',
        tabContentHeader: '持续上涨',
        tabContent: '机票价格实时波动。大数据价格预测显示，未来价格上涨概率较大，建议尽快预订。但是就像天气预报一样，预测数据存在一定误差，仅供参考。',
        spanClassName: 'futrue-rise',
        iconClassName: 'ico-rise',
        tinyClassName: 'rise',
        ubtValue: 'up'
    },
    '3': {
        floatSideTitle: '可能涨价',
        plainTabHeader: '未来价格可能会',
        strongTabHeader: '上涨',
        tabContentHeader: '大幅上涨',
        tabContent: '机票价格实时波动。大数据价格预测显示，未来价格上涨概率较大，建议尽快预订。但是就像天气预报一样，预测数据存在一定误差，仅供参考。',
        spanClassName: 'futrue-rise',
        iconClassName: 'ico-rise',
        tinyClassName: 'rise',
        ubtValue: 'up'
    }
}