import React, { useEffect, useState } from 'react'
import { fetchPost } from '../../../actions/common'
import CONFIG_URL from '../../../constants/list/api'

const Gift = ({ giftIdList, prevCond, currentSegmentSeq }) => {
    const [gifts, setGifts] = useState([]),
        aCityName = prevCond && prevCond.getIn(['flightSegments', currentSegmentSeq, 'arrivalCityName']),
        dCityName = prevCond && prevCond.getIn(['flightSegments', currentSegmentSeq, 'departureCityName'])

    useEffect(() => {
        giftIdList && giftIdList.length && fetchPost(CONFIG_URL.getFlightGiftInfo, { giftIdList })
            .then(res => res && res.data || {})
            .then(res => {
                if (res && res.data) {
                    setGifts(res.data)
                }
            }).catch(_e => {
                setGifts([])
            })
    }, [giftIdList])

    return gifts && gifts.length ? <li className='gix-box-recommend'>
        <div className="title">惊喜礼盒</div>

        <div className="content low-price-remind-content gix-box-recommend-content">
            {
                gifts.length == 1 ? <React.Fragment>
                    <p>预订{`${dCityName}-${aCityName}`}机票可享{gifts[0].description}</p>
                </React.Fragment> : <React.Fragment>
                        <p>预订{`${dCityName}-${aCityName}`}机票可享{gifts.length}重惊喜好礼</p>
                        {gifts.map((gift, index) => {
                            const { description } = gift
                            return <p key={index}>{index + 1}.{description}</p>
                        })}
                    </React.Fragment>
            }

        </div></li> : null

}
export default Gift
