import { createSelector } from 'reselect'
import { prevCondSelector, isBuildUpSelector, currentSegmentSeqSelector } from '../flight/baseSelector';

export const getGuideLineSelector = (state) => state.getIn(['list', 'guideLine'])
export const getShowGuileLineSelector = createSelector([prevCondSelector, currentSegmentSeqSelector, isBuildUpSelector],
    (prevCond, currentSegment, isBuildUp) => {
        const flightWay = prevCond.get('flightWay')
        return flightWay !== 'M' && (currentSegment === 0 || isBuildUp)
    })

