import React from 'react'
import moment from 'moment'
import toNewListPageV2 from '@/src/sources/list/converter/toNewListPageV2';
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../ubt.v2/list'
import { scopeFunction } from '../../../sources/list/scopeFunction'
import { PAGE_LIST_URL } from '../../../constants/common';

const genAdjcentRecommendWrapper = ({ prevCond, adjacentDistance, adjacentCityCode, flightSegmentList }) => {
    const recommendFirstSegment = flightSegmentList.first(),
        prevCondFirstSegment = prevCond.getIn(['flightSegments', 0]),
        adjcentCity = adjacentCityCode && adjacentDistance,      //临近城市推荐
        adjcentDepartCity = adjcentCity ? prevCondFirstSegment.get('departureCityCode') === adjacentCityCode : false,
        adjcentCityOriginalName = adjcentCity ? (adjcentDepartCity ? prevCondFirstSegment.get('departureCityName') : prevCondFirstSegment.get('arrivalCityName')) : '',
        adjcentCityNewName = adjcentCity ? (adjcentDepartCity ? recommendFirstSegment.get('departureCityName') : recommendFirstSegment.get('arrivalCityName')) : ''

    return {
        adjcentCity,
        adjcentCityOriginalName,
        adjcentCityNewName,
        recommendFirstSegment
    }
}

const genFlightOperate = ({ tag, totalPrice, priceDescription }) => {
    return (
        <div className='flight-operate'>
            <div className='tags'>
                {tag && <div className='tag'>
                    {tag}
                </div>}
            </div>
            {totalPrice ? (
                <div className='price-box'>
                    <div className='price'>
                        <dfn>¥</dfn>{totalPrice}<span className='qi'>起</span>
                    </div>
                    <div className='tax'>
                        {priceDescription}
                    </div>
                </div>) : null}
        </div>
    )
}

const genDistancePart = ({ adjcentCity, adjcentCityOriginalName, adjcentCityNewName, adjacentDistance }) => {
    return (
        adjcentCity ? (
            <div className='distance'>
                {adjcentCityOriginalName}距{adjcentCityNewName}{adjacentDistance}km
            </div>) : null
    )
}

const genUrl = (flightSegmentList, { cabin, adult, child, infant }) => {
    if (flightSegmentList && flightSegmentList.size) {
        let finalUrl = ''
        const length = flightSegmentList.size,
            // oneway-sha-hkg?depdate=2019-12-13&cabin=y_s&adult=1&child=0&infant=0
            // round-sha-hkg?depdate=2019-08-20_2019-08-21&cabin=c_f&adult=1&child=0&infant=0
            // multi-sha-hkg-hkg-sel?depdate=2019-12-13_2019-12-14&cabin=y_s&adult=1&child=0&infant=0
            firstSegment = flightSegmentList.get(0),
            departureCityCode = firstSegment.get('departureCityCode'),
            arrivalCityCode = firstSegment.get('arrivalCityCode'),
            departureDate = firstSegment.get('departureDate'),
            trafficType = firstSegment.get('trafficType'),
            isTrain = trafficType == 'TRAIN'

        if (length === 1) {
            finalUrl = `oneway-${departureCityCode}-${arrivalCityCode}?depdate=${departureDate}&cabin=${cabin}&adult=${adult}&child=${child}&infant=${infant}&noRecommend=1`
        } else {
            const secondSegment = flightSegmentList.get(1),
                departureCityCode2 = secondSegment.get('departureCityCode'),
                arrivalCityCode2 = secondSegment.get('arrivalCityCode'),
                departureDate2 = secondSegment.get('departureDate'),
                //往返或者多程，通过判断去程和返程的出发/到达城市是否相反
                departureAndArriveReverse = departureCityCode === arrivalCityCode2 && arrivalCityCode === departureCityCode2

            if (length === 2 && departureAndArriveReverse) {
                // 相反表示是往返航线
                finalUrl = `round-${departureCityCode}-${arrivalCityCode}?depdate=${departureDate}_${departureDate2}&cabin=${cabin}&adult=${adult}&child=${child}&infant=${infant}`
            } else {
                // 否则表示是多程
                const joinedCity = flightSegmentList.map(item => item.get('departureCityCode') + '-' + item.get('arrivalCityCode')).join('-'),
                    joinedDate = flightSegmentList.map(item => item.get('departureDate')).join('_')

                finalUrl = `multi-${joinedCity}?depdate=${joinedDate}&cabin=${cabin}&adult=${adult}&child=${child}&infant=${infant}`
            }
        }
        if (isTrain) {
            let departureCityName = encodeURI(firstSegment.get('departureCityName'));
            let arrivalCityName = encodeURI(firstSegment.get('arrivalCityName'));
            return `http://trains.ctrip.com/TrainBooking/Search.aspx?day=${departureDate}&fromCn=${departureCityName}&toCn=${arrivalCityName}&mkt_header=xcj`
        }


        return `${PAGE_LIST_URL}/${finalUrl}`
    } else {
        console.error(`invalid params: flightSegmentList: `, flightSegmentList)
        return ''
    }
}

const onClickFlightBox = (url, ubtData) => toNewListPageV2(url, ubtData)

const onOtherSiteUrlClick = (url) => {
    window.open(url)
}
const getRecommendItemTitle = scopeFunction({
    i: ({ title }) => {
        return <h3 dangerouslySetInnerHTML={{ __html: title }} />

    }, d: ({ title, totalPrice, theMinPriceOfAllFlights, showSavePrice }) => {
        let savePrice = parseInt(theMinPriceOfAllFlights.get('totalPrice') - totalPrice)
        if (savePrice > 0 && showSavePrice) {
            return <h3 dangerouslySetInnerHTML={{ __html: title + `省&yen;${savePrice}` }} />
        } else {
            return <h3 dangerouslySetInnerHTML={{ __html: title }} />
        }
    }
})
// 临近日期有直飞
const AdjacentDateRecommend_1 = ({ searchInfo, resultInfo, ubtData, prevCond, totalPrice, priceDescription, adjacentDistance, adjacentCityCode, title, flightSegmentList, tag, url }) => {
    const recommendFirstSegment = flightSegmentList.first(),
        departureDateOfFirstSegment = moment(recommendFirstSegment.get('departureDate')).format('MM-DD'),
        departureCityNameOfFirstSegment = recommendFirstSegment.get('departureCityName'),
        arrivalCityNameOfFirstSegment = recommendFirstSegment.get('arrivalCityName'),
        departureWeekDayOfFirstSegment = recommendFirstSegment.get('week'),
        departureIntervalDaysOfFirstSegment = recommendFirstSegment.get('intervalDays')

    const { adjcentCity, adjcentCityOriginalName,
        adjcentCityNewName } = genAdjcentRecommendWrapper({ prevCond, adjacentDistance, adjacentCityCode, flightSegmentList })

    const recommendSecondSegment = flightSegmentList.get(1),
        departureDateOfSecondSegment = recommendSecondSegment ? moment(recommendSecondSegment.get('departureDate')).format('MM-DD') : '',
        departureWeekDayOfSecondSegment = recommendSecondSegment ? recommendSecondSegment.get('week') : '',
        departureIntervalDaysOfSecondSegment = recommendSecondSegment ? recommendSecondSegment.get('intervalDays') : ''

    const genSegmentEntry = (prefix, date, weekday, deptureIntervalDays) => {
        return (
            <div className='day'>{prefix}{date} {weekday}{deptureIntervalDays ? `（${deptureIntervalDays < 0 ? '早' : '晚'}${Math.abs(deptureIntervalDays)}天）` : ''}</div>
        )
    }
    return (
        <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW, LIST_UBT_KEY_TYPES.RECOMMEND_INFO, ubtData)}>
            <div className='recommend-item day-recommend'>
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
                <div className='recommend-content' onClick={_ => onClickFlightBox(url, { ...ubtData, OriginFlight: { ...resultInfo, ...searchInfo } })}>
                    <div className='flight-box'>
                        <div className='days'>
                            {genSegmentEntry('去：', departureDateOfFirstSegment, departureWeekDayOfFirstSegment, departureIntervalDaysOfFirstSegment)}
                            {departureDateOfSecondSegment && genSegmentEntry('返：', departureDateOfSecondSegment, departureWeekDayOfSecondSegment, departureIntervalDaysOfSecondSegment)}
                        </div>

                        <div className='flights'>
                            <div className='flight-item'>
                                <div className='depart'>{departureCityNameOfFirstSegment}</div>
                                <div className={`arrow-box arrow-${recommendSecondSegment ? 'rt' : 'ow'}`}></div>
                                <div className='depart'>{arrivalCityNameOfFirstSegment}</div>
                            </div>
                        </div>
                        {genDistancePart({ adjcentCity, adjcentCityOriginalName, adjcentCityNewName, adjacentDistance })}
                    </div>
                    {genFlightOperate({ tag, totalPrice, priceDescription })}
                </div>
            </div>
        </UbtBoundary>
    )
}

// 临近航线更便宜 （从杭州出发，省￥100）
const AdjacentRoundAirlineRecommend_2 = ({ searchInfo, resultInfo, ubtData, prevCond, totalPrice, priceDescription, adjacentDistance, adjacentCityCode, title, flightSegmentList, tag, url, theMinPriceOfAllFlights, showSavePrice }) => {
    const { adjcentCity, adjcentCityOriginalName,
        adjcentCityNewName, recommendFirstSegment } = genAdjcentRecommendWrapper({ prevCond, adjacentDistance, adjacentCityCode, flightSegmentList })

    return (
        <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW, LIST_UBT_KEY_TYPES.RECOMMEND_INFO, ubtData)}>
            <div className='recommend-item distance-recommend'>
                {getRecommendItemTitle({
                    totalPrice,
                    title,
                    theMinPriceOfAllFlights,
                    showSavePrice
                })}
                <div className='recommend-content' onClick={_ => onClickFlightBox(url, { ...ubtData, OriginFlight: { ...resultInfo, ...searchInfo } })}>
                    <div className='flight-box'>
                        <div className='flights'>
                            <div className='flight-item'>
                                <div className='depart'>
                                    {recommendFirstSegment.get('departureCityName')}
                                </div>
                                <div className={`arrow-box arrow-${flightSegmentList.size === 2 ? 'rt' : 'ow'}`}></div>
                                <div className='depart'>
                                    {recommendFirstSegment.get('arrivalCityName')}
                                </div>
                            </div>
                        </div>
                        {genDistancePart({ adjcentCity, adjcentCityOriginalName, adjcentCityNewName, adjacentDistance })}
                    </div>
                    {genFlightOperate({ tag, totalPrice, priceDescription })}
                </div>
            </div>
        </UbtBoundary>
    )
}

// 临近航线有直飞
const AdjacentAirlineRecommend_3 = ({ searchInfo, resultInfo, ubtData, prevCond, totalPrice, priceDescription, adjacentDistance, adjacentCityCode, title, flightSegmentList, tag, url, theMinPriceOfAllFlights, showSavePrice }) => {
    const { adjcentCity, adjcentCityOriginalName,
        adjcentCityNewName, recommendFirstSegment } = genAdjcentRecommendWrapper({ prevCond, adjacentDistance, adjacentCityCode, flightSegmentList })

    return (
        <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW, LIST_UBT_KEY_TYPES.RECOMMEND_INFO, ubtData)}>
            <div className='recommend-item distance-recommend'>
                {getRecommendItemTitle({
                    totalPrice,
                    title,
                    theMinPriceOfAllFlights,
                    showSavePrice
                })}
                <div className='recommend-content' onClick={_ => onClickFlightBox(url, { ...ubtData, OriginFlight: { ...resultInfo, ...searchInfo } })}>
                    <div className='flight-box'>
                        <div className='flights'>
                            <div className='flight-item'>
                                <div className='depart'>
                                    {recommendFirstSegment.get('departureCityName')}
                                </div>
                                <div className={`arrow-box arrow-${flightSegmentList.size === 2 ? 'rt' : 'ow'}`}></div>
                                <div className='depart'>
                                    {recommendFirstSegment.get('arrivalCityName')}
                                </div>
                            </div>
                        </div>
                        {genDistancePart({ adjcentCity, adjcentCityOriginalName, adjcentCityNewName, adjacentDistance })}
                    </div>
                    {genFlightOperate({ tag, totalPrice, priceDescription })}
                </div>
            </div>
        </UbtBoundary>
    )
}

// 缺口程  （曲线出行，省￥100）
const AdjacentOpenAirlineRecommend_4 = ({ searchInfo, resultInfo, ubtData, prevCond, totalPrice, priceDescription, adjacentDistance, adjacentCityCode, title, flightSegmentList, tag, url }) => {
    const { adjcentCity, adjcentCityOriginalName, adjcentCityNewName } = genAdjcentRecommendWrapper({ prevCond, adjacentDistance, adjacentCityCode, flightSegmentList })
    return (
        <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW, LIST_UBT_KEY_TYPES.RECOMMEND_INFO, ubtData)}>
            <div className='recommend-item distance-recommend'>
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
                <div className='recommend-content' onClick={_ => onClickFlightBox(url, { ...ubtData, OriginFlight: { ...resultInfo, ...searchInfo } })}>
                    <div className='flight-box'>
                        <div className='flights'>
                            {flightSegmentList.map(seg => {
                                return (
                                    <div key={seg.get('departureCityCode')} className='flight-item'>
                                        <div className='depart'>
                                            {seg.get('departureCityName')}
                                        </div>
                                        <div className='arrow-box arrow-ow'></div>
                                        <div className='depart'>
                                            {seg.get('arrivalCityName')}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {genDistancePart({ adjcentCity, adjcentCityOriginalName, adjcentCityNewName, adjacentDistance })}
                    </div>
                    {genFlightOperate({ tag, totalPrice, priceDescription })}
                </div>
            </div>
        </UbtBoundary>
    )
}
// 火车票
const OtherTrafficTypeDirectRecommend = ({ searchInfo, resultInfo, ubtData, _prevCond, totalPrice, priceDescription, _adjacentDistance, _adjacentCityCode, title, flightSegmentList, tag, url }) => {
    const recommendFirstSegment = flightSegmentList.first(),
        departureCityNameOfFirstSegment = recommendFirstSegment.get('departureCityName'),
        arrivalCityNameOfFirstSegment = recommendFirstSegment.get('arrivalCityName')
    return (
        <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW, LIST_UBT_KEY_TYPES.RECOMMEND_INFO, ubtData)}>
            <div className='recommend-item distance-recommend'>
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
                <div className='recommend-content' onClick={_ => onOtherSiteUrlClick(url, { ...ubtData, OriginFlight: { ...resultInfo, ...searchInfo } })}>
                    <div className='flight-box'>
                        <div className='flights'>
                            <div className='flight-item'>
                                <div className='depart'>
                                    {departureCityNameOfFirstSegment}
                                </div>
                                <div className={`arrow-box arrow-${flightSegmentList.size === 2 ? 'rt' : 'ow'}`}></div>
                                <div className='depart'>
                                    {arrivalCityNameOfFirstSegment}
                                </div>
                            </div>
                        </div>
                    </div>
                    {genFlightOperate({ tag, totalPrice, priceDescription })}
                </div>
            </div>
        </UbtBoundary>
    )
}


const RECOMMEND_TYPE_MAP = {
    NEARBY_DATE_DIRECT_RECOMMEND: {
        type: 'NearbyDateDirectRecommend',
        value: 2,
        title: '临近日期推荐',
        includeKeys: ['NEARBY_DATE_DIRECT_RECOMMEND', 'D_NEAR_TIME'],
        componentClass: AdjacentDateRecommend_1
    },
    ADJACENT_RECOMMEND: {
        type: 'AdjacentRecommend',
        value: 1,
        title: '邻近航线推荐',
        includeKeys: ['ADJACENT_RECOMMEND', 'D_ROUND_NEAR'],
        componentClass: AdjacentRoundAirlineRecommend_2,
        showSavePrice: true
    },
    // 临近直飞
    ADJACENT_DIRECT_RECOMMEND: {
        type: 'AdjacentDirectRecommend',
        value: 6,
        title: '邻近航线直飞推荐',
        includeKeys: ['ADJACENT_DIRECT_RECOMMEND', 'D_NEAR_FLIGHT', 'D_ROUND_NEAR_TIME'],
        componentClass: AdjacentAirlineRecommend_3,
        showSavePrice: true
    },
    OPEN_JAW_RECOMMEND: {
        type: 'OjRecommend',
        value: 10,
        title: '缺口程推荐',
        includeKeys: ['OPEN_JAW_RECOMMEND', 'D_OPEN_JAW'],
        componentClass: AdjacentOpenAirlineRecommend_4
    },
    OPEN_JAW_DIRECT_RECOMMEND: {
        type: 'OJDirectRecommend',
        value: 12,
        title: '缺口程直飞推荐',
        includeKeys: ['OPEN_JAW_DIRECT_RECOMMEND'],
        componentClass: AdjacentOpenAirlineRecommend_4
    },
    // 火车票
    D_TRAIN: {
        type: 'D_TRAIN',
        value: 13,
        title: '火车票',
        includeKeys: ['D_TRAIN'],
        componentClass: OtherTrafficTypeDirectRecommend
    }

}
const getRecomendConfig = (type) => {
    let configKey = Object.keys(RECOMMEND_TYPE_MAP).find(key => {
        let config = RECOMMEND_TYPE_MAP[key]
        if (config.includeKeys && config.includeKeys.indexOf(type) >= 0) {
            return true
        }
    })
    return configKey && RECOMMEND_TYPE_MAP[configKey]
}
const covertRecommendUbtData = (recommend, order) => {
    let type = recommend.get('recommendType')
    let sequence = () => {
        let flightSegmentList = recommend.get('flightSegmentList')
        return flightSegmentList && flightSegmentList.map((seg, index) => {
            return {
                Segno: index,
                From: seg.get('departureCityCode'),
                To: seg.get('arrivalCityCode'),
                StartTime: seg.get('departureDate')
            }
        })
    }
    const recommendTypeConfig = getRecomendConfig(type)
    return {
        "Type": recommendTypeConfig.type,
        "value": recommendTypeConfig.value,
        "Title": recommendTypeConfig.title,
        "Order": order,
        "Sequence": sequence() || [],
        "Price": recommend.get('totalPrice'),
        "IsTax": "T",
        "Duration": recommend.get('duration')
    }
}


export const FloatSideRecommend = (props) => {
    const { allRecommendFlightsList, prevCond, passengerCountArrayFromPrevCond, resultInfo, searchInfo, theMinPriceOfAllFlights } = props
    if (allRecommendFlightsList && allRecommendFlightsList.length) {
        return allRecommendFlightsList.map((recommend, index) => {
            const theRecommendTitle = recommend.title,
                recommendFlights = recommend.flights,
                classname = recommend.className,

                titleYenIndex = theRecommendTitle.indexOf('&yen;'),
                containsYenFlag = titleYenIndex >= 5,   // 防止出现 ￥ 符号后面的数字被截断
                needWrapTitle = theRecommendTitle.length > (containsYenFlag ? 9 : 5),
                recommendTitle = needWrapTitle ? `${theRecommendTitle.substring(0, 4)}<br>${theRecommendTitle.substring(4)}` : theRecommendTitle
            if (recommendTitle && recommendFlights && recommendFlights.size) {
                const [adult, child, infant] = passengerCountArrayFromPrevCond
                const cabin = prevCond.get('cabin')

                return (
                    <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW, LIST_UBT_KEY_TYPES.RECOMMEND_IS_SHOW)}>
                        <li className={`${classname || 'journey-plan'}`}>
                            <div className='title'>
                                {
                                    needWrapTitle ? <p dangerouslySetInnerHTML={{ __html: recommendTitle }} /> : recommendTitle
                                }
                            </div>
                            <div className='content journey-plan-content'>
                                {recommendFlights.map((item, index) => {
                                    const type = item.get('recommendType'),
                                        title = item.get('title'),
                                        config = getRecomendConfig(type),
                                        recommendComponentClass = config && config.componentClass,
                                        showSavePrice = config && config.showSavePrice,
                                        flightSegmentList = item.get('flightSegmentList')

                                    if (recommendComponentClass) {
                                        // 渲染特定类型推荐
                                        return React.createElement(recommendComponentClass, {
                                            key: title + index,
                                            totalPrice: item.get('totalPrice'),
                                            priceDescription: item.get('priceDescription'),
                                            tag: item.get('tag'),
                                            adjacentDistance: item.get('adjacentDistance'),
                                            adjacentCityCode: item.get('adjacentCityCode'),
                                            title: item.get('title'),
                                            flightSegmentList,
                                            prevCond,
                                            ubtData: covertRecommendUbtData(item, index + 1),
                                            resultInfo,
                                            searchInfo,
                                            url: genUrl(flightSegmentList, { cabin, adult, child, infant }),
                                            showSavePrice,
                                            theMinPriceOfAllFlights
                                        })
                                    } else {
                                        console.error(`unknown recommend Type: ${type}, all known recommend types: ${Object.keys(RECOMMEND_TYPE_MAP).join(',')}`)
                                        return null
                                    }
                                })}
                            </div>
                        </li>
                    </UbtBoundary>

                )
            } else {
                return null
            }
        })
    } else {
        return null
    }


}
